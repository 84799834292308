<template>
  <section class="page-section about" id="about">
    <div class="container">
      <h2 class="text-center mb-0">เกี่ยวกับเรา</h2>
      <div class="divider-custom">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon">
          <i class="fa-solid fa-comment"></i>
        </div>
        <div class="divider-custom-line"></div>
      </div>
      <div class="row justify-content-center px-4">
        <div class="col-sm-8 col-md-8 col-lg-4">
          <p>สินเชื่อเพื่อธุรกิจ: วิธีการขยายธุรกิจของคุณ
การขยายธุรกิจเป็นสิ่งที่ต้องการของผู้ประกอบการทุกคน แต่มันไม่ใช่เรื่องง่ายเสมอไป เพื่อที่จะขยายธุรกิจของคุณได้อย่างเต็มประสิทธิภาพ สินเชื่อเพื่อธุรกิจอาจเป็นตัวช่วยที่คุณต้องการ
</p>
<p>
สินเชื่อเพื่อธุรกิจคืออะไร?
สินเชื่อเพื่อธุรกิจเป็นสินเชื่อที่ให้กับธุรกิจเพื่อช่วยเสริมสร้างทุนเพื่อการขยายธุรกิจ โดยปกติแล้ว สินเชื่อเพื่อธุรกิจจะมีอัตราดอกเบี้ยที่ต่ำกว่าสินเชื่อส่วนบุคคล เนื่องจากธุรกิจมีความเสี่ยงสูงกว่าบุคคลทั่วไป
</p>
<p>
วิธีการใช้สินเชื่อเพื่อธุรกิจ
การใช้สินเชื่อเพื่อธุรกิจต้องมีการวางแผนอย่างดี เพื่อให้สามารถชำระเงินกลับได้เป็นระยะเวลาที่กำหนด และไม่ทำให้ธุรกิจของคุณติดลบ</p>
        </div>
        <div class="col-sm-8 col-md-8 col-lg-4">
          <p>การขอสินเชื่อเพื่อธุรกิจ
การขอสินเชื่อเพื่อธุรกิจจะต้องมีเอกสารประกอบการพิจารณา ซึ่งอาจจะแตกต่างกันไป แต่โดยทั่วไปแล้ว จะต้องมีเอกสารเช่นสำเนาบัตรประชาชน สำเนาทะเบียนบ้าน สำเนาทะเบียนนิติบุคคล และเอกสารประกอบการยืนยันรายได้
</p>
<p>
ประเภทของสินเชื่อเพื่อธุรกิจ
สินเชื่อเพื่อธุรกิจมีหลายประเภท เช่น สินเชื่อที่มีคนค้ำประกัน และสินเชื่อที่ไม่ต้องมีคนค้ำประกัน ซึ่งการเลือกประเภทของสินเชื่อที่เหมาะสมกับธุรกิจของคุณจะช่วยให้คุณได้รับประโยชน์มากที่สุด
</p>
<p>
ประโยชน์ของสินเชื่อเพื่อธุรกิจ
การขยายธุรกิจเป็นสิ่งที่ต้องการของผู้ประกอบการทุกคน แต่มันไม่ใช่เรื่องง่ายเสมอไป เพื่อที่จะขยายธุรกิจของคุณได้อย่างเต็มประสิทธิภาพ สินเชื่อเพื่อธุรกิจอาจเป็นตัวช่วยที่คุณต้องการ</p>
        </div>
      </div>
    </div>
  </section>
  <section class="page-section">
    <div class="container">
      <h2 class="text-center mb-0">ทำไมต้องเพิ่มทรัพย์</h2>
      <div class="divider-custom">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon">
          <i class="fa-solid fa-comment"></i>
        </div>
        <div class="divider-custom-line"></div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-8 col-md-3 col-lg-3 text-center" v-for="(item, index) in data" :key="index">
          <div class="row">
            <div class="col-12 text-center">
              <p><img :src="item.image" class="img-fluid"></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <strong>{{ item.header }}</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutSection',
  data() {
    return {
      data: [
        {
          image: "assets/about1.png",
          header: "ฟรี ค่าบริการ",
          description: "บริการจากเรา ไม่มีค่าบริการใด ๆ แอบแฝงทั้งสิ้น"
        },
        {
          image: "assets/about2.png",
          header: "ฟรี ค่าโอน",
          description: "บริการจากเรา ไม่มีการให้โอนมัดจำหรือโอนดอกเบี้ยก่อน"
        },
        {
          image: "assets/about3.png",
          header: "ลงพื้นที่รับเงินถึงมือ",
          description: "เรามีทีมงานลงพื้นที่คอยให้บริการคุณถึงหน้าบ้าน"
        },
        {
          image: "assets/about4.png",
          header: "อนุมัติไว",
          description: "รวดเร็ว ทันใจ ตรวจสอบเอกสาร อนุมัติไว ไม่ต้องรอนาน"
        },
        {
          image: "assets/about5.png",
          header: "เชื่อถือได้ 100%",
          description: "ได้จดทะเบียนถูกต้องตามกฏหมาย"
        },
        {
          image: "assets/about6.png",
          header: "ไม่ต้องค้ำประกัน",
          description: "ไม่ต้องใช้หลักทรัพย์ค้ำประกันประเมินฟรีไม่มีค่าใช้จ่าย"
        },
        {
          image: "assets/about7.png",
          header: "เอกสารน้อย",
          description: "ใช้เอกสารน้อย ไม่ต้องเสียเวลารวบรวมเอกสาร สะดวกรวดเร็ว"
        },
        {
          image: "assets/about8.png",
          header: "วงเงินสูงสุด",
          description: "กู้สินเชื่อกับเราที่นี่เราให้วงเงินกู้สูงสุดถึง 5 ล้านบาท"
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h2 {
    font-weight: bold;
  }
  .img-fluid {
    width: 35%;
  }
</style>

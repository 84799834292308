<template>
  <section class="page-section contact" id="contact">
    <div class="container">
      <h2 class="text-center mb-0">ติดต่อเรา</h2>
      <div class="divider-custom">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon">
          <i class="fa-solid fa-envelope"></i>
        </div>
        <div class="divider-custom-line"></div>
      </div>
      <div class="row justify-content-center">
        <div class="col-8">
          <form>
            <div class="row">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="exampleInputName" v-model="form.name">
                <label for="exampleInputName" class="form-label">ชื่อ</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="exampleInputPhone" v-model="form.phone">
                <label for="exampleInputPhone" class="form-label">เบอร์โทร</label>
              </div>
              <div class="form-floating mb-3">
                <input type="email" class="form-control" id="exampleInputEmail" v-model="form.email">
                <label for="exampleInputEmail" class="form-label">อีเมล</label>
              </div>
              <div class="form-floating mb-3">
                <textarea class="form-control" id="exampleInputMessage" rows="3" v-model="form.message"></textarea>
                <label for="exampleInputMessage" class="form-label">ข้อความ</label>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <button type="button" class="btn btn-primary float-right" v-on:click="submit()">ส่งข้อมูล</button>
                &nbsp;
                <button type="button" class="btn btn-secondary" v-on:click="clear()">ยกเลิก</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <section class="contact" id="map">
    <div class="container-fluid">
      <h2 class="text-center mb-0">แผนที่</h2>
      <div class="divider-custom">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon">
          <i class="fa-solid fa-star"></i>
        </div>
        <div class="divider-custom-line"></div>
      </div>
      <div class="row">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.1737053165866!2d100.56627861483129!3d13.82860579029775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29cf936530af1%3A0xe9277cd4bf709501!2s1839%20Phahonyothin%20Road%2C%20Khwaeng%20Lat%20Yao%2C%20Khet%20Chatuchak%2C%20Krung%20Thep%20Maha%20Nakhon%2010900!5e0!3m2!1sen!2sth!4v1685885305881!5m2!1sen!2sth" 
          height="450" style="border:0;" allowfullscreen="" loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </section>
</template>

<script>

const axios = require('axios');
const Swal = require('sweetalert2')

export default {
  name: 'ContactSection',
  data() {
    return {
      form: {
        name: "",
        phone: "",
        email: "",
        message: ""
      }
    }
  },
  methods: {
    clear() {
      this.form = {
        name: "",
        phone: "",
        email: "",
        message: ""
      }
    },
    async submit() {
      if (this.form.name != "" && this.form.phone != "" && this.form.email != "" && this.form.message != "") {
        let message = "\nข้อความแจ้งเตือนจาก\n";
        message += "ชื่อ " + this.form.name + "\n"
        message += "เบอร์โทรศัพท์ " + this.form.phone + "\n"
        message += "อีเมล์ " + this.form.email + "\n"
        message += "ข้อความ " + this.form.message

        let api = axios.create({
          timeout: 60 * 1000, // 1 minute
          headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
              'Access-Control-Allow-Headers': '*'
          },
        });

        await api.post(
          'https://permsubsme.com/api.php',
          { message: message }
        )
        .then(function (response) {
          if (response.data.status == 200 && response.data.message == "ok") {
            // console.log('sent message to line')
            Swal.fire(
              'Success',
              '',
              'success'
            )
            this.clear()
          } else {
            console.log('error')
          }
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
              'Error',
              '',
              'error'
            )
        })
        .finally(function () {
          // always executed
        });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h2 {
    font-weight: bold;
  }
  .contact {
    background-color: #F3F3F3;
  }
  .form-floating > label {
    padding-left: 25px !important;
  }
  .contact button {
    width: 100px;
  }
  button {
    border-radius: 20px;
  }
  .btn-primary {
    background-color: #a449ff;
    border-color: #a449ff;
  }
  .btn-primary:hover {
    background-color: #9400ff;
    border-color: #9400ff;
  }
</style>

<template>
  <section class="page-section service" id="service">
    <div class="container">
      <h2 class="text-center mb-0">บริการของเรา</h2>
      <div class="divider-custom">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon">
          <i class="fa-solid fa-comment"></i>
        </div>
        <div class="divider-custom-line"></div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4 text-center" v-for="(item, index) in data" :key="index">
          <div class="row">
            <div class="col-12 text-center">
              <p><img :src="item.image" class="img-fluid"></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <strong>{{ item.header }}</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ServiceSection',
  data() {
    return {
      data: [
        {
          image: "assets/service1.png",
          header: "ร้านอาหาร",
          description: "อนุมัติ 1,000,000+"
        },
        {
          image: "assets/service2.png",
          header: "ร้านค้าออนไลน์",
          description: "อนุมัติ 2,000,000+"
        },
        {
          image: "assets/service3.png",
          header: "จำหน่ายปลีก - ส่ง",
          description: "อนุมัติ 2,000,000+"
        },
        {
          image: "assets/service4.png",
          header: "โรงแรม / รีสอร์ท",
          description: "อนุมัติ 3,000,000+"
        },
        {
          image: "assets/service5.png",
          header: "ขนส่ง",
          description: "อนุมัติ 3,000,000+"
        },
        {
          image: "assets/service6.png",
          header: "เจ้าของธุรกิจอื่นๆ",
          description: "อนุมัติ 3,000,000+"
        },
        {
          image: "assets/service7.png",
          header: "เหมางานระบบ / ก่อสร้าง",
          description: "อนุมัติ 5,000,000+"
        },
        {
          image: "assets/service8.png",
          header: "โกดังสินค้าขนาดใหญ่",
          description: "อนุมัติ 10,000,000+"
        },
        {
          image: "assets/service9.png",
          header: "โรงงานผลิต",
          description: "อนุมัติ 10,000,000+"
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h2 {
    font-weight: bold;
  }
</style>

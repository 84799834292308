<template>
  <section class="page-section howto" id="howto">
    <div class="container">
      <h2 class="text-center mb-0">ขั้นตอนการสมัคร</h2>
      <div class="divider-custom">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon">
          <i class="fa-solid fa-comment"></i>
        </div>
        <div class="divider-custom-line"></div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4 text-center" v-for="(item, index) in howtos" :key="index">
          <div class="row">
            <div class="col-12 text-center">
              <p><img :src="item.image" class="img-fluid"></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <p>{{ item.header }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="page-section">
    <div class="container">
      <h2 class="text-center mb-0">เอกสารใช้การขอสินเชื่อ</h2>
      <div class="divider-custom">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon">
          <i class="fa-solid fa-star"></i>
        </div>
        <div class="divider-custom-line"></div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4 text-center" v-for="(item, index) in docs" :key="index">
          <div class="row">
            <div class="col-12 text-center">
              <p><img :src="item.image" class="img-fluid img-doc"></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <p>{{ item.header }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HowtoSection',
  data() {
    return {
      howtos: [
      {
          image: "assets/howto1.png",
          header: "1 ติดต่อเจ้าหน้าที่"
        },
        {
          image: "assets/howto2-2.png",
          header: "2 จัดส่งเอกสาร"
        },
        {
          image: "assets/howto3.png",
          header: "3 ประเมินเอกสาร"
        }
      ],
      docs: [
        {
          image: "assets/howto_doc1.png",
          header: "สำเนาบัตรประชาชน"
        },
        {
          image: "assets/howto_doc2.png",
          header: "สำเนาทะเบียนบ้าน"
        },
        {
          image: "assets/howto_doc3.png",
          header: "หนังสือจดทะเบียนกิจการ"
        },
        {
          image: "assets/howto_doc4.png",
          header: "Stagement ย้อนหลัง 3 เดือน"
        },
        {
          image: "assets/howto_doc5.png",
          header: "แผนที่"
        },
        {
          image: "assets/howto_doc6.png",
          header: "รูปภาพภายในกิจการ"
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h2 {
    font-weight: bold;
  }
  #howto {
    background-color: #F3F3F3;
  }
  .img-doc {
    height: 142px;
  }
</style>

<template>
  <BannerSection />
  <AboutSection />
  <ServiceSection />
  <HowtoSection />
  <ContactSection />
  <FooterSection />
</template>

<script>
import BannerSection from './components/BannerSection.vue'
import AboutSection from './components/AboutSection.vue'
import ServiceSection from './components/ServiceSection.vue'
import HowtoSection from './components/HowtoSection.vue'
import ContactSection from './components/ContactSection.vue'
import FooterSection from './components/FooterSection.vue'

export default {
  name: 'App',
  components: {
    BannerSection,
    AboutSection,
    ServiceSection,
    HowtoSection,
    ContactSection,
    FooterSection
  }
}
</script>

<style>
  .page-section {
    padding: 6rem 0;
  }
  /* Header Section */
  .divider-custom {
    margin:1.25rem 0 1.5rem;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center
  }
  .divider-custom .divider-custom-line {
    width:100%;
    max-width:7rem;
    height:.25rem;
    background-color:#2c3e50;
    border-radius:1rem;
    border-color:#2c3e50!important
  }
  .divider-custom .divider-custom-line:first-child {
    margin-right:1rem
  }
  .divider-custom .divider-custom-line:last-child {
    margin-left:1rem
  }
  .divider-custom .divider-custom-icon {
    color:#2c3e50!important;
    font-size:2rem
  }
  .divider-custom.divider-light .divider-custom-line {
    background-color:#fff
  }
  .divider-custom.divider-light .divider-custom-icon {
    color:#fff!important
  }
  /* Header Section */
</style>

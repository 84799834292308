<template>
    <section class="footer" id="footer">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-2 col-md-2 col-sm-2">
                    <img src="assets/logo.png" class="img-fluid" id="logo">
                </div>
                <div class="col-lg-4 col-md-4 col-sm-10">
                    <p>เราคือบริษัทให้บริการทางด้านสินเชื่อเพื่อธุรกิจขนาดกลาง–ใหญ่ บริการอย่างใกล้ชิด ดุจญาติมิตรที่รู้ใจ ยินดีให้บริการ และคำปรึกษา ฟรี!! เพราะเราเข้าใจคุณและธุรกิจของคุณเป็นอย่างดี</p>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                    <div class="row">
                        <div class="col-12">
                            <strong>แผนที่เว็บไซด์</strong>
                        </div>
                    </div>
                    <div class="row link-site-map">
                        <div class="col-12">
                            <a href="#page-top">หน้าแรก</a>
                        </div>
                    </div>
                    <div class="row link-site-map">
                        <div class="col-12">
                            <a href="#about">เกี่ยวกับเรา</a>
                        </div>
                    </div>
                    <div class="row link-site-map">
                        <div class="col-12">
                            <a href="#service">บริการของเรา</a>
                        </div>
                    </div>
                    <div class="row link-site-map">
                        <div class="col-12">
                            <a href="#howto">สมัคร</a>
                        </div>
                    </div>
                    <div class="row link-site-map">
                        <div class="col-12">
                            <a href="#map">แผนที่</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-12">
                    <div class="row">
                        <div class="col-12">
                            <strong>ติดต่อเรา</strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <span>Tel : 066-124-4782 (คุณตั้ม)</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <span>1839 ถ. พหลโยธิน แขวงลาดยาว เขตจตุจักร กรุงเทพมหานคร 10900</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <a href="https://lin.ee/IoW3rpV" target="_blank">
                                <img src="assets/line.png" class="img-fluid">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="copy-right" id="copy-right">
        <div class="container">
            <div class="row justify-content-center not-mobile">
                <div class="col-lg-6 col-md-6 col-sm-12">@ สงวนสิทธิ์ 2566 เพิ่มทรัพย์</div>
                <div class="col-lg-4 col-md-6 col-sm-12">นโยบายความเป็นส่วนตัว | นโยบายการใช้งานคุกกี้</div>
            </div>
            <!-- <div class="row mobile">
                <div class="col-5 text-center mobile-icon-phone"><i class="fa-solid fa-phone-volume"></i></div>
                <div class="col-2 text-center mobile-line">|</div>
                <div class="col-5 text-center mobile-line-logo"><strong>LINE</strong></div>
            </div> -->
        </div>
    </section>
    <section class="mobile" id="mobilet">
        <div class="container">
            <div class="row">
                <div class="col-5 text-center mobile-icon-phone">
                    <a href="tel:0661244782">
                        <i class="fa-solid fa-phone-volume"></i>
                    </a>
                </div>
                <div class="col-2 text-center mobile-line">|</div>
                <div class="col-5 text-center mobile-line-logo">
                    <a href="https://lin.ee/IoW3rpV" target="_blank">
                        <strong>LINE</strong>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'FooterSection'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #footer {
        background-color: #E9D98B;
    }
    #footer > .container {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    #footer a {
        color: #000000;
        text-decoration: none;
    }
    #copy-right {
        background-color: #a449ff;
        color: #FFFFFF;
    }
    .link-site-map {
        padding-left: 5px;
        margin-bottom: 5px;
    }
    #copy-right .container .row {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .mobile-icon-phone, .mobile-line-logo, .mobile-line {
        font-size: 30px;
    }
    .mobile a {
        color: #FFFFFF;
        text-decoration: none;
    }

    @media only screen and (max-width: 768px) {

        .mobile {
            background-color: #a449ff;
            display: flex;
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            color: #FFFFFF;
            padding-top: 10px;
            padding-bottom: 10px;
            z-index: 10000 !important;
        }
        .not-mobile {
            display: none;
        }
        #footer {
            padding-bottom: 50px;
        }
    }
    @media only screen and (min-width: 768px) {
        .mobile {
            display: none;
        }
        .not-mobile {
            display: flex;
        }
    }
</style>
